'use strict';

angular.module('uasApp').constant('PAGES', [
    {
        name: 'activity-card',
        component: 'activity-card-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'category',
                tooltip: 'Only show activities of the selected category.',
                editor: function() {
                    return {
                        html: `
                            <method-category-select
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </method-category-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'activity-form',
        component: 'activity-form-page',
        parameters: [
            {
                name: 'category',
                tooltip: 'The method category for filtering the activities',
                editor: function() {
                    return {
                        html: `
                            <method-category-select
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </method-category-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'activity-grid',
        component: 'activity-grid-page',
        parameters: [
            {
                name: 'mode',
                placeholder: 'edit'
            },
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'hideScheduleWeek',
                type: 'boolean',
                tooltip: 'Hide the schedule week from display.'
            },
            {
                name: 'hideDate',
                type: 'boolean',
                tooltip: 'Hide the schedule dates from display.'
            },
            {
                name: 'hideCopy',
                type: 'boolean',
                tooltip: 'Hide the copy button.'
            },
            {
                name: 'expandAll',
                type: 'boolean'
            },
            {
                name: 'category',
                tooltip: 'The method category for adding new activities. If no value is selected, the user can select the method category.',
                editor: function() {
                    return {
                        html: `
                            <method-category-select
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </method-category-select>
                        `
                    };
                }
            },
            {
                name: 'filterOnCategory',
                tooltip: 'Only show activities with the selected method category.',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'activity-list',
        component: 'activity-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'category',
                tooltip: 'The method category for adding new activities. If no value is selected, the user can select the method category. If the activity is nested, the method category is inherited instead.',
                editor: function() {
                    return {
                        html: `
                            <method-category-select
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </method-category-select>
                        `
                    };
                }
            },
            {
                name: 'filterOnCategory',
                tooltip: 'Only show activities with the selected method category.',
                type: 'boolean'
            },
            {
                name: 'disableType',
                tooltip: 'Hide the activity type selection when the parent entity has a type.',
                type: 'boolean'
            },
            {
                name: 'columns',
                tooltip: 'Select (i.a.) activity custom fields to display for each activity.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                definitions="definitions"
                                root-type="{{ page.filterType || page.rootType }}"
                                entity-type="activity"
                                ng-model="parameter.value">
                            </column-parameter>
                        `,
                        scope: {
                            definitions: [
                                { name: 'code', label: 'Name' },
                                { name: 'weeks', label: 'Weeks' },
                                { name: 'scheduleWeeks', label: 'Schedule weeks' },
                                { name: 'duration', label: 'Duration' },
                                { name: 'totalDuration', label: 'Total duration' }
                            ]
                        }
                    };
                }
            }
        ]
    },
    {
        name: 'activity-report',
        component: 'activity-report-page',
        parameters: [
            {
                name: 'exam',
                tooltip: 'Show exam activities when true, or teaching when false.',
                type: 'boolean'
            },
            {
                name: 'hideReportTemplates',
                type: 'boolean',
                tooltip: 'Hides the report template selection.'
            },
            {
                name: 'defaultTemplate',
                editor: function() {
                    return {
                        html: `
                            <report-template-select
                                type="activities"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </report-template-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'additional',
        component: 'additional-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'advice',
        component: 'advice-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'forceNewAdvice',
                type: 'boolean',
                tooltip: 'When selected it is required to give new advice.'
            },
            {
                name: 'role',
                tooltip: 'Select role for which advices can be created and viewed.',
                editor: function() {
                    return {
                        html: `
                            <role-type-select
                                select-id="roles"
                                entity-type="{{ page.rootType }}"
                                parent="true"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </role-type-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'appraisal-report',
        component: 'appraisal-report-page',
        parameters: [
            {
                name: 'type',
                isArray: true,
                editor: function() {
                    return {
                        html: `
                            <method-type-select
                                exam="true"
                                multiple-values="true"
                                prefix-property="code"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </method-type-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'appraisals',
        component: 'appraisal-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'showInactive',
                type: 'boolean',
                tooltip: 'View changes (includes inactive appraisals) by default.'
            },
            {
                name: 'disableNavigate',
                type: 'boolean',
                tooltip: 'Disable navigate to assessment by disabling click.'
            },
            {
                name: 'category',
                tooltip: 'Only show assessments of the selected category.',
                editor: function() {
                    return {
                        html: `
                            <method-category-select
                                exam="true"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </method-category-select>
                        `
                    };
                }
            },
            {
                name: 'columns',
                tooltip: 'Select appraisal custom fields to show for each non-nested assessment.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                root-type="{{ page.filterType || page.rootType }}"
                                entity-type="appraisal,assessment"
                                ng-model="parameter.value">
                            </column-parameter>
                        `
                    };
                }
            },
            {
                name: 'childPage',
                tooltip: 'The activity page that is displayed on the appraisals page.',
                editor: function() {
                    return {
                        html: `
                            <page-select
                                select-id="childPage"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </page-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'assessments-report',
        component: 'assessments-report-page',
        parameters: [
            {
                name: 'showCreate',
                type: 'boolean',
                tooltip: 'Show create button to add a new assessment.'
            },
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'modal',
                type: 'boolean',
                tooltip: 'On click a modal opens with the assessment workflow, when disabled we redirect to the assessment.'
            },
            {
                name: 'hideReportTemplates',
                type: 'boolean',
                tooltip: 'Hides the report template selection.'
            },
            {
                name: 'reportTemplate',
                editor: function() {
                    return {
                        html: `
                            <report-template-select
                                type="assessments"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </report-template-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'assets',
        component: 'asset-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'assignments',
        component: 'assignment-list-page',
        parameters: [
            {
                name: 'showInactive',
                type: 'boolean',
                tooltip: 'View changes (includes inactive assignments) by default.'
            }
        ]
    },
    {
        name: 'availability',
        component: 'availability-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'availability-report',
        component: 'availability-report-page',
        parameters: [
            {
                name: 'hideFilter',
                type: 'boolean',
                tooltip: 'Enable to close the filter by default'
            }
        ]
    },
    {
        name: 'capacity',
        component: 'capacity-page'
    },
    {
        name: 'changes',
        component: 'changes-details-page'
    },
    {
        name: 'changes-list',
        component: 'changes-list-page',
        parameters: [
            {
                name: 'hideEmpty',
                type: 'boolean',
                tooltip: 'Hide changes that have no visible fields.'
            },
            {
                name: 'showFilters',
                type: 'boolean',
                tooltip: 'Show the filter options by default, otherwise it needs to be expanded.'
            },
            {
                name: 'processTypeId',
                label: 'process',
                tooltip: 'Filter on changes of this process type, when empty the user can filter.',
                editor: function() {
                    return {
                        html: `
                            <process-type-parameter
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </process-type-parameter>
                        `
                    };
                }
            },
            {
                name: 'filterProcess',
                type: 'boolean',
                tooltip: 'By default fallback on workflow process.'
            }
        ]
    },
    {
        name: 'changes-report',
        component: 'change-report-page'
    },
    {
        name: 'comments',
        component: 'comments-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'filterProcess',
                type: 'boolean',
                tooltip: 'Only show comments of the process.'
            }
        ]
    },
    {
        name: 'complete',
        component: 'uas-workflow-complete'
    },
    {
        name: 'cost',
        component: 'cost-page'
    },
    {
        name: 'cost-division-report',
        component: 'cost-division-report-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'showCalculate',
                type: 'boolean',
                tooltip: 'Show button to copy from previous period.'
            }
        ]
    },
    {
        name: 'credits',
        component: 'credits-page'
    },
    {
        name: 'delivery-report',
        component: 'delivery-report-page'
    },
    {
        name: 'description-list',
        component: 'description-list-page',
        parameters: [
            {
                name: 'type'
            },
            {
                name: 'noDescriptionsLabel',
                editor: function() {
                    return {
                        html: `
                            <label-input
                                input-id="noDescriptionsLabel"
                                ng-model="parameter.value">
                            </label-input>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'description-report',
        component: 'description-report-page'
    },
    {
        name: 'descriptions',
        component: 'descriptions-page',
        parameters: [
            {
                name: 'types',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <description-type-parameter
                                parameter="parameter"
                                root-type="{{ page.rootType }}"
                                ng-model="parameter.value">
                            </description-type-parameter>
                        `
                    };
                }
            },
            {
                name: 'editor',
                tooltip: 'The type of text editor, supported editors are: text, html'
            },
            {
                name: 'mode',
                placeholder: 'edit',
                tooltip: 'The default view mode: history, preview or edit'
            },
            {
                name: 'viewMode',
                placeholder: 'single',
                tooltip: 'The default view mode: sideways, single or below'
            },
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'showHelp',
                type: 'boolean',
                tooltip: 'Show the required and optional languages in an alert box.'
            },
            {
                name: 'showIncompleteHelp',
                type: 'boolean',
                tooltip: 'Show an alert when one or more required fields have not been filled in.'
            }
        ]
    },
    {
        name: 'descriptions-approved',
        component: 'descriptions-approved-page'
    },
    {
        name: 'discussion',
        component: 'discussion-page',
        parameters: [
            {
                name: 'showAdvice',
                type: 'boolean'
            },
            {
                name: 'showNotes',
                type: 'boolean'
            },
            {
                name: 'showStatus',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'document',
        component: 'document-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'type',
                editor: function() {
                    return {
                        html: `
                            <enum-options
                                select-id="type"
                                type="'DocumentType'"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </enum-options>
                        `
                    };
                }
            },
            {
                name: 'documentTypes',
                isArray: true,
                tooltip: 'Select the document types that are allowed on the page',
                editor: function() {
                    return {
                        html: `
                            <element-options
                                input-id="documentTypes"
                                type="'DOCUMENT_TYPE'"
                                multiple-values="true"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </element-options>
                        `
                    };
                }
            },
            {
                name: 'nameFormula',
                type: 'string',
                tooltip: 'Expression to generate document name. Can contain document fields and entity fields :entityCode and :entityExternalId.',
                editor: function () {
                    return {
                        html: `
                            <expression-input
                                input-id="nameFormula"
                                ng-model="parameter.value"
                                placeholder="join(:entityCode, '-', :(document)typeId, '-', now())">
                            </expression-input>
                        `
                    };
                }
            },
            {
                name: 'columns',
                tooltip: 'Select document custom fields to display for each document.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                root-type="{{ page.filterType || page.rootType }}"
                                entity-type="document"
                                ng-model="parameter.value">
                            </column-parameter>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'document-report',
        component: 'document-report-page',
        parameters: [
            {
                name: 'hideReportTemplates',
                type: 'boolean',
                tooltip: 'Hides the report template selection.'
            },
            {
                name: 'reportTemplate',
                editor: function() {
                    return {
                        html: `
                            <report-template-select
                                type="document"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </report-template-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'effort-list',
        component: 'effort-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'showUnassigned',
                type: 'boolean',
                tooltip: 'Also show persons who are not assigned to an organisation.'
            }
        ]
    },
    {
        name: 'effort-report',
        component: 'effort-report-page'
    },
    {
        name: 'evaluation-feedback',
        component: 'evaluation-feedback-page',
        operation: 'VIEW_EVALUATION',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'evaluationType',
                required: true,
                type: 'string'
            },
            {
                name: 'role',
                type: 'string',
                required: true,
                tooltip: 'External id of the role for which feedback is given'
            },
            {
                name: 'editorOptions',
                type: 'string',
                tooltip: 'Comma-separated list of options for the html editor',
                placeholder: 'bold, italic, underline,list,bullet,link'
            }
        ]
    },
    {
        name: 'evaluation-participate',
        component: 'evaluation-participate-page',
        operation: 'VIEW_EVALUATION',
        parameters: [{
            name: 'evaluationType',
            required: true,
            type: 'string'
        }, {
            name: 'forceComplete',
            tooltip: 'Evaluation must be completed before proceeding',
            type: 'boolean'
        }, {
            name: 'openPopup',
            tooltip: 'Open participation form in popup',
            type: 'boolean'
        }]
    },
    {
        name: 'evaluation-report',
        component: 'evaluation-report-page',
        operation: 'VIEW_EVALUATION',
        parameters: [{
            name: 'evaluationType',
            required: true,
            type: 'string'
        }, {
            name: 'urlPattern',
            type: 'string'
        }]
    },
    {
        name: 'expire',
        component: 'uas-workflow-expiration'
    },
    {
        name: 'export',
        component: 'export-page',
        operation: 'EXPORT',
        parameters: [{
            name: 'legend',
            type: 'string',
            tooltip: 'Comma-separated list of root types to show in the legend'
        }]
    },
    {
        name: 'form',
        component: 'form-page',
        items: true,
        comments: true,
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'operationsToEdit',
                placeholder: 'EDIT_ADDITIONAL_WORKFLOW'
            }
        ]
    },
    {
        name: 'general',
        component: 'general-page'
    },
    {
        name: 'groups-report',
        component: 'groups-report-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'modal',
                type: 'boolean'
            },
            {
                name: 'showAll',
                type: 'boolean'
            },
            {
                name: 'hideReportTemplates',
                type: 'boolean'
            },
            {
                name: 'reportTemplate',
                editor: function() {
                    return {
                        html: `
                            <report-template-select
                                type="groups"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </report-template-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'group-studies',
        component: 'group-studies-page'
    },
    {
        name: 'hour-report',
        component: 'hour-report-page'
    },
    {
        name: 'item',
        component: 'item-page',
        items: true,
        comments: true,
        parameters: [
            {
                name: 'code',
                required: true,
                editor: function() {
                    return {
                        html: `
                            <item-type-select
                                root-type="page.rootType"
                                ng-model="parameter.value"
                                ng-change="onChange()"
                                ng-required="true">
                            </item-type-select>
                        `
                    };
                }
            },
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'labelWidth'
            },
            {
                name: 'vertical',
                type: 'boolean',
                tooltip: 'Items are shown in vertical mode.'
            },
            {
                name: 'showDates',
                type: 'boolean',
                tooltip: 'Show the start and end period of an item.'
            },
            {
                name: 'showInactive',
                type: 'boolean',
                tooltip: 'Show inactive items by default.'
            },
            {
                name: 'showTotals',
                type: 'boolean',
                tooltip: 'Show the numeric total of the items.'
            },
            {
                name: 'showMine',
                type: 'boolean',
                tooltip: 'Only show items created by the current user.'
            }
        ]
    },
    {
        name: 'item-report',
        component: 'item-report-page',
        parameters: [
            {
                name: 'typeCode',
                required: true,
                editor: function() {
                    return {
                        html: `
                            <item-type-select
                                root-type="page.filterType"
                                ng-model="parameter.value"
                                ng-change="onChange()"
                                ng-required="true">
                            </item-type-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'label',
        component: 'label-page',
        items: true
    },
    {
        name: 'link-list',
        component: 'link-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'categoryId',
                label: 'category',
                editor: function() {
                    return {
                        html: `
                            <link-category-select
                                ng-model="parameter.value"
                                ng-change="onChange()"
                                select-id="categoryId">
                            </link-category-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'link-builder',
        component: 'link-builder-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'categoryId',
                label: 'category',
                editor: function() {
                    return {
                        html: `
                            <link-category-select
                                ng-model="parameter.value"
                                ng-change="onChange()"
                                select-id="categoryId">
                            </link-category-select>
                        `
                    };
                }
            }
        ]
    },    {
        name: 'track',
        component: 'track-page'
    },
    {
        name: 'track-assign',
        component: 'track-assign-page'
    },
    {
        name: 'track-report',
        component: 'track-report-page'
    },
    {
        name: 'method-list',
        component: 'method-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'active',
                type: 'boolean',
                tooltip: 'Default only show active methods.'
            },
            {
                name: 'preventWorkflow',
                type: 'boolean',
                tooltip: 'Prevent edit by users with only workflow access.'
            },
            {
                name: 'showEdit',
                type: 'boolean',
                tooltip: 'Show edit button on methods.'
            },
            {
                name: 'showBudget',
                type: 'boolean',
                tooltip: 'Show the comparison in budget compared to original.'
            },
            {
                name: 'showEffort',
                type: 'boolean',
                tooltip: 'Show the calculated effort in hours.'
            },
            {
                name: 'autoSave',
                type: 'boolean',
                tooltip: 'Saves the new, changed or deleted method immediately.'
            },
            {
                name: 'columns',
                tooltip: 'Select up to 3 method custom fields to display for each method.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                selected-columns-max="3"
                                root-type="method"
                                entity-type="method"
                                ng-model="parameter.value">
                            </column-parameter>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'method-parameter',
        component: 'method-parameter-page',
        tooltip: 'Pageable list of method parameters.',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'hideCopy',
                type: 'boolean',
                tooltip: 'Hide the copy button that copies method parameters from previous academic year.'
            },
            {
                name: 'showAllCategories',
                type: 'boolean',
                tooltip: 'Indicates if all method categories should be displayed. When not selected only categories with budget selected will be displayed.'
            }
        ]
    },
    {
        name: 'method-report',
        component: 'method-report-page',
        tooltip: 'Pageable report of methods',
        parameters: []
    },
    {
        name: 'method-tree',
        component: 'method-tree-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'showInactive',
                type: 'boolean',
                tooltip: 'View changes (includes inactive methods) by default.'
            },
            {
                name: 'disableNavigate',
                type: 'boolean',
                tooltip: 'Disable navigate to method by disabling click'
            },
            {
                name: 'columns',
                tooltip: 'Select up to 2 method-schema custom fields to display for each method.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                selected-columns-max="2"
                                root-type="{{ page.filterType || page.rootType }}"
                                entity-type="method-schema,method"
                                ng-model="parameter.value">
                            </column-parameter>
                        `
                    };
                }
            },
            {
                name: 'childPage',
                tooltip: 'The activity page that is displayed on the method-tree page.',
                editor: function() {
                    return {
                        html: `
                            <page-select
                                select-id="childPage"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </page-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'module-division',
        component: 'cost-division-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'showInactive',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'module-groups',
        component: 'module-groups-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'redirect',
                type: 'boolean',
                tooltip: 'Should show link redirecting to the group details.'
            },
            {
                name: 'type',
                tooltip: 'Should only show groups of type, shows all when blank.',
                editor: function() {
                    return {
                        html: `
                            <group-type-select
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </group-type-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'module-resources',
        component: 'uas-workflow-module-resources',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'module-studies',
        component: 'module-studies-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'module-worklog',
        component: 'uas-workflow-module-worklog',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'modules-report',
        component: 'modules-report-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'modal',
                type: 'boolean',
                tooltip: 'On click a modal opens with the module workflow, when disabled we redirect to the module.'
            },
            {
                name: 'showAll',
                type: 'boolean',
                tooltip: 'Shows all modules, regardless of the context.'
            },
            {
                name: 'hideNested',
                type: 'boolean',
                tooltip: 'Hide modules from nested groups.'
            },
            {
                name: 'mode',
                editor: function() {
                    return {
                        html: `
                            <enum-options
                                select-id="mode"
                                type="'StudyReportFilter'"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </enum-options>
                        `
                    };
                }
            },
            {
                name: 'reportTemplate',
                editor: function() {
                    return {
                        html: `
                            <report-template-select
                                type="modules"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </report-template-select>
                        `
                    };
                }
            },
            {
                name: 'hideReportTemplates',
                type: 'boolean',
                tooltip: 'Hides the report template selection.'
            }
        ]
    },
    {
        name: 'note',
        component: 'note-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'showEmpty',
                tooltip: 'Show information alert when empty.',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'notifications',
        component: 'notifications-page'
    },
    {
        name: 'objective-report',
        component: 'objective-report-page',
        parameters: [
            {
                name: 'type',
                editor: function () {
                    return {
                        html: `
                            <element-options
                                input-id="subjectType"
                                type="'SubjectType'"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </element-options>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'objective-tree',
        component: 'objective-tree-page',
        parameters: [
            {
                name: 'type',
                editor: function () {
                    return {
                        html: `
                            <element-options
                                input-id="subjectType"
                                type="'SubjectType'"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </element-options>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'objectives',
        component: 'objective-page',
        operation: 'VIEW_OBJECTIVES',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'category',
                tooltip: 'Only show assessments of the selected category.',
                editor: function() {
                    return {
                        html: `
                            <method-category-select
                                exam="true"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </method-category-select>
                        `
                    };
                }
            },
            {
                name: 'columns',
                tooltip: 'Select (i.a.) objective custom fields to display for each objective.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                definitions="definitions"
                                root-type="objective"
                                ng-model="parameter.value">
                            </column-parameter>
                        `,
                        scope: {
                            definitions: [
                                { name: 'name', label: 'Name' },
                                { name: 'subjects', label: 'Subjects' },
                                { name: 'assessments', label: 'Assessments' }
                            ]
                        }
                    };
                }
            },
            {
                name: 'showAssessmentCode',
                type: 'boolean',
                tooltip: 'Show (technical) code of assessments.'
            },
            {
                name: 'showSubjectCode',
                type: 'boolean',
                tooltip: 'Show (technical) code of subjects.'
            },
            {
                name: 'selectAllSubjects',
                type: 'boolean',
                tooltip: 'Select all subject types instead of only subject types defined on entity.'
            },
            {
                name: 'showInactive',
                type: 'boolean',
                tooltip: 'View changes (includes inactive objectives) by default.'
            },
            {
                name: 'hideAssessment',
                type: 'boolean',
                tooltip: 'When selected the assessment will not be displayed on the objective modal.'
            },
            {
                name: 'hideSubject',
                type: 'boolean',
                tooltip: 'When selected the subject will not be displayed on the objective modal.'
            }
        ]
    },
    {
        name: 'offerings',
        component: 'offering-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'showInactive',
                type: 'boolean',
                tooltip: 'View changes (includes inactive offerings) by default.'
            },
            {
                name: 'hideEdit',
                type: 'boolean'
            },
            {
                name: 'adhoc',
                type: 'boolean',
                tooltip: 'Create ad-hoc offerings.'
            },
            {
                name: 'columns',
                tooltip: 'Select offering custom fields to display for each offering.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                definitions="definitions"
                                root-type="{{ page.filterType || page.rootType }}"
                                entity-type="offering"
                                ng-model="parameter.value">
                            </column-parameter>
                        `,
                        scope: {
                            definitions: [
                                { name: 'startDate', label: 'Start date' },
                                { name: 'endDate', label: 'End date' }
                            ]
                        }
                    };
                }
            }
        ]
    },
    {
      name: 'offering-report',
      component: 'offering-report-page'
    },
    {
        name: 'operations',
        component: 'operations-page'
    },
    {
        name: 'person-roles',
        component: 'person-roles-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean',
                tooltip: 'Cannot add or edit roles.'
            }
        ]
    },
    {
        name: 'planner',
        component: 'uas-planner-page',
        parameters: [
            {
                name: 'showPeriod',
                type: 'boolean',
                tooltip: 'User can select another starting period than academic year.'
            }
        ]
    },
    {
        name: 'preview',
        component: 'preview-page'
    },
    {
        name: 'process-expander',
        component: 'process-expander-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'process-manager',
        component: 'process-manager-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'type',
                editor: function() {
                    return {
                        html: `
                            <process-type-parameter
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </process-type-parameter>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'process-participants',
        component: 'process-participants-page'
    },
    {
        name: 'process-report',
        component: 'process-report-page',
        parameters: [
            {
                name: 'type',
                editor: function() {
                    return {
                        html: `
                            <process-type-parameter
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </process-type-parameter>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'process-status',
        component: 'process-status-page'
    },
    {
        name: 'qualifications-award',
        component: 'award-qualification-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'columns',
                tooltip: 'Select (i.a.) award qualification custom fields to display for each qualification.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                definitions="definitions"
                                root-type="{{ page.filterType || page.rootType }}"
                                entity-type="award-qualification"
                                ng-model="parameter.value">
                            </column-parameter>
                        `,
                        scope: {
                            definitions: [
                                { name: 'qualification', label: 'Qualification' },
                                { name: 'sequence', label: 'Sequence' },
                                { name: 'rule', label: 'Rule' },
                                { name: 'description', label: 'Description' }
                            ]
                        }
                    };
                }
            }
        ]
    },
    {
        name: 'qualifications-report',
        component: 'qualifications-report-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'hideReportTemplates',
                type: 'boolean'
            },
            {
                name: 'reportTemplate',
                editor: function() {
                    return {
                        html: `
                            <report-template-select
                                type="qualifications"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </report-template-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'relations',
        component: 'relations-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'showInactive',
                type: 'boolean',
                tooltip: 'View changes (includes inactive relations) by default.'
            },
            {
                name: 'types',
                editor: function() {
                    return {
                        html: `
                            <role-type-parameter
                                parameter="parameter"
                                root-type="{{ page.rootType }}"
                                ng-model="parameter.value">
                            </role-type-parameter>
                        `
                    };
                },
                tab: true
            }
        ]
    },
    {
        name: 'relations-list',
        component: 'relations-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'relations-report',
        component: 'relations-report-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean',
                tooltip: 'Cannot edit relation on click.'
            },
            {
                name: 'target',
                type: 'boolean',
                tooltip: 'Only show relations targeted towards this entity.'
            },
            {
                name: 'vacancy',
                type: 'boolean',
                tooltip: 'Only show relations that are still in vacancy.'
            },
            {
                name: 'modal',
                type: 'boolean',
                tooltip: 'On click opens modal to edit relation.'
            }
        ]
    },
    {
        name: 'rows',
        component: 'rows-page',
        composite: true,
        parameters: [
            {
                name: 'collapsible',
                type: 'boolean'
            },
            {
                name: 'collapsed',
                type: 'boolean',
                tooltip: 'By default, collapse all collapsible subpages.'
            },
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'displayHeader',
                type: 'boolean',
                tooltip: 'Display the header of child pages.'
            },
            {
                name: 'displayPrint',
                type: 'boolean'
            },
            {
                name: 'hideTitle',
                type: 'boolean',
                tooltip: 'Hide the title of the rows page.'
            }
        ]
    },
    {
        name: 'schedule-preference',
        component: 'schedule-preference-page',
        parameters: [
            {
                name: 'displayInfo',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'schedule-preference-report',
        component: 'schedule-preference-report-page'
    },
    {
        name: 'specification',
        component: 'specification-page',
        rootType: 'SPECIFICATION',
        items: true,
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'status-table',
        component: 'status-table-page'
    },
    {
        name: 'structure',
        component: 'structure-page',
        parameters: [
            {
                name: 'defaultSort',
                tooltip: 'Sort property of the first column, default is "code", but can be changed to "name".',
                placeholder: 'code'
            },
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'compare',
                type: 'boolean',
                tooltip: 'User can compare study to another study.'
            },
            {
                name: 'showPeriod',
                type: 'boolean',
                tooltip: 'User can select another starting period than academic year.'
            },
            {
                name: 'showInactive',
                type: 'boolean',
                tooltip: 'Also show groups and modules that are no longer active.'
            },
            {
                name: 'showRedirect',
                type: 'boolean',
                tooltip: 'Should show link redirecting to the group or module details.'
            },
            {
                name: 'showDescription',
                type: 'boolean',
                tooltip: 'Should default group description when expanded.'
            },
            {
                name: 'editDescription',
                type: 'boolean',
                tooltip: 'Edit default group description when expanded, default read only.'
            },
            {
                name: 'hideEditOffering',
                type: 'boolean',
                tooltip: 'Hide the edit button on the offering page.'
            },
            {
                name: 'markInactiveOfferings',
                type: 'boolean',
                tooltip: 'Strikethrough modules that are not offered in the current academic year or period.',
                defaultValue: true
            },
            {
                name: 'expandAll',
                type: 'boolean',
                tooltip: 'Expand all groups, including not-owned ones, when clicking the "expand all" button.'
            },
            {
                name: 'groupColumns',
                tooltip: 'Select up to 2 module group or (module group) additional custom fields to display for each module group.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                selected-columns-max="2"
                                root-type="module-group"
                                entity-type="module-group,additional"
                                ng-model="parameter.value">
                            </column-parameter>
                        `
                    };
                }
            },
            {
                name: 'moduleColumns',
                tooltip: 'Select up to 2 module or (module) additional custom fields to display for each module.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                selected-columns-max="2"
                                root-type="module"
                                entity-type="module,additional"
                                ng-model="parameter.value">
                            </column-parameter>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'studies-report',
        component: 'studies-report-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'modal',
                type: 'boolean'
            },
            {
                name: 'showAll',
                type: 'boolean'
            },
            {
                name: 'hideReportTemplates',
                type: 'boolean'
            },
            {
                name: 'reportTemplate',
                editor: function() {
                    return {
                        html: `
                            <report-template-select
                                type="studies"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </report-template-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'study-check',
        component: 'uas-workflow-study-check'
    },
    {
        name: 'study-cost',
        component: 'study-cost-page'
    },
    {
        name: 'study-links',
        component: 'study-links-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'categoryId',
                label: 'category',
                editor: function() {
                    return {
                        html: `
                            <link-category-select
                                ng-model="parameter.value"
                                ng-change="onChange()"
                                select-id="categoryId">
                            </link-category-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'study-modules-report',
        component: 'study-modules-report-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'modal',
                type: 'boolean'
            },
            {
                name: 'mode',
                editor: function() {
                    return {
                        html: `
                            <enum-options
                                select-id="mode"
                                type="'StudyReportFilter'"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </enum-options>
                        `
                    };
                }
            },
            {
                name: 'hideReportTemplates',
                type: 'boolean'
            },
            {
                name: 'reportTemplate',
                editor: function() {
                    return {
                        html: `
                            <report-template-select
                                type="study.module.group.modules"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </report-template-select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'study-planboard',
        component: 'planboard-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'mode',
                placeholder: 'edit'
            },
            {
                name: 'modes',
                placeholder: 'history,normal,edit'
            },
            {
                name: 'showAdvice',
                type: 'boolean'
            },
            {
                name: 'showPrint',
                type: 'boolean'
            },
            {
                name: 'extraAttributes',
                tooltip: 'Choose extra attributes for the planboard when in normal mode. When only one attribute is selected, the selection option will not be displayed. If multiple attributes are selected, the attributes configured here can be adjusted by the user.',
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                definitions="definitions"
                                root-type="{{ page.filterType || page.rootType }}"
                                entity-type="module"
                                ng-model="parameter.value">
                            </column-parameter>
                        `,
                        scope: {
                            definitions: [
                                { name: 'subjects', label: 'Subjects' },
                                { name: 'scales', label: 'Scale value of selected subject' },
                                { name: 'type', label: 'Module type' }
                            ]
                        }
                    };
                }
            },
            {
                name: 'subjectType',
                tooltip: 'Set a fixed value for the type of subjects selectable.',
                editor: function() {
                    return {
                        html: `
                            <element-options
                                input-id="subjectType"
                                type="'SubjectType'"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </element-options>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'study-planboard-print',
        component: 'planboard-print-page'
    },
    {
        name: 'study-studyable',
        component: 'uas-workflow-study-studyable'
    },
    {
        name: 'subject-matrix',
        component: 'subject-matrix-page',
        operation: 'VIEW_SUBJECTS_REPORT',
        parameters: [
            {
                name: 'type',
                editor: function() {
                    return {
                        html: `
                            <element-options
                                input-id="subjectType"
                                type="'SubjectType'"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </element-options>
                        `
                    };
                }
            },
            {
                name: 'groupColumns',
                tooltip: 'Select up to 3 module group or (module group) additional custom fields to display for each module group.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                selected-columns-max="3"
                                root-type="module-group"
                                entity-type="module-group,additional"
                                ng-model="parameter.value">
                            </column-parameter>
                        `
                    };
                }
            },
            {
                name: 'moduleColumns',
                tooltip: 'Select up to 3 module or (module) additional custom fields to display for each module.',
                tab: true,
                editor: function() {
                    return {
                        html: `
                            <column-parameter
                                info-label="{{ parameter.tooltip }}"
                                selected-columns-max="3"
                                root-type="module"
                                entity-type="module,additional"
                                ng-model="parameter.value">
                            </column-parameter>
                        `
                    };
                }
            },
            {
                name: 'showPhase',
                type: 'boolean',
                tooltip: 'Show the module phase in study.'
            },
            {
                name: 'showPeriods',
                type: 'boolean',
                tooltip: 'Show the offered periods of a module.'
            },
            {
                name: 'showStructure',
                type: 'boolean',
                tooltip: 'Show the structure with expandable groups.'
            },
            {
                name: 'stickyHeaders',
                type: 'boolean',
                tooltip: 'Make the headers sticky when scrolling. When the structure is shown, only the column headers are sticky. Note: enabling this option could cause horizontal overflow on small screens.'
            },
            {
                name: 'expandFilters',
                type: 'boolean',
                tooltip: 'Expand the filters by default.'
            },
            {
                name: 'displayType',
                tooltip: 'The type that will be displayed in the report.',
                editor: function() {
                    return {
                        html: `
                            <select
                                id="displayType"
                                name="displayType"
                                class="form-control"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                                <option value="" selected translate>
                                    Static.Placeholder.NoOptionSelected
                                </option>
                                <option value="assessments">Assessments</option>
                                <option value="scales">Scales</option>
                            </select>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'subject-types',
        component: 'subject-type-page',
        operation: 'EDIT_SUBJECT_TYPES',
        parameters: [
            {
                name: 'type',
                editor: function() {
                    return {
                        html: `
                            <element-options
                                input-id="subjectType"
                                type="'SubjectType'"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </element-options>
                        `
                    };
                }
            }
        ]
    },
    {
        name: 'subjects',
        component: 'subject-page',
        operation: 'VIEW_SUBJECTS',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'category',
                editor: function() {
                    return {
                        html: `
                            <subject-category-select
                                ng-model="parameter.value"
                                ng-change="onChange()"
                                id-model="true">
                            </subject-category-select>
                        `
                    };
                }
            },
            {
                name: 'type',
                editor: function() {
                    return {
                        html: `
                            <element-options
                                input-id="subjectType"
                                type="'SubjectType'"
                                ng-model="parameter.value"
                                ng-change="onChange()">
                            </element-options>
                        `
                    };
                }
            },
            {
                name: 'showInactive',
                type: 'boolean',
                tooltip: 'View changes (includes inactive subjects) by default.'
            }
        ]
    },
    {
        name: 'tasks-list',
        component: 'task-list-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'hidePersonal',
                type: 'boolean',
                tooltip: 'Always hide personal (non educational) tasks in this list.'
            }
        ]
    },
    {
        name: 'tasks-report',
        component: 'tasks-report-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'teacher-schedule',
        component: 'teacher-schedule-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            },
            {
                name: 'displayInfo',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'teams',
        component: 'teams-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'text',
        component: 'text-page'
    },
    {
        name: 'vacancies',
        component: 'vacancies-page',
        parameters: [
            {
                name: 'readOnly',
                type: 'boolean'
            }
        ]
    },
    {
        name: 'welcome',
        component: 'uas-workflow-welcome',
        parameters: [
            {
                name: 'showStatus',
                type: 'boolean',
                tooltip: 'Show the status transitions with messages.'
            }
        ]
    },
    {
        name: 'workload-report',
        component: 'workload-report'
    },
    {
        name: 'workload-study-report',
        component: 'workload-study-report'
    }
]);
